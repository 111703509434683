import React from "react"
import { StaticImage } from "gatsby-plugin-image"

/*


                  <img
                  className="object-cover object-center rounded-3xl shadow-2xl"
                  src="https://images.unsplash.com/photo-1507207611509-ec012433ff52?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=934&q=80"
                  alt=""
                />

*/

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/

// object-cover object-center rounded-3xl shadow-2xl
export default function Section1() {
  return (
    <div id="qui" className="bg-white pt-16 lg:py-24">
      <div className="pb-16 bg-sky-600 lg:pb-0 lg:z-10 lg:relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
          <div className="relative lg:-my-8">
            <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden" />
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
              <div className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                <StaticImage 
                    className="lg:h-full"
                    src="../../images/mickael-sanchez.jpg"
                    alt="Cabinet Intervaps"
                  />
              </div>
            </div>
          </div>
          <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
              <h2 className="text-3xl font-extrabold text-white">
              Mickaël Sanchez, Expert d’assurés indépendant
              </h2>
              <p className="text-lg text-white py-2">
              Mon intérêt est le même que le vôtre&nbsp;: faire valoir la plus juste indemnité qui vous sera allouée après un sinistre.
              </p>
              <p className="text-lg text-white py-2">
              Vous avez besoin d’un conseil après un sinistre, vous souhaitez être accompagné ou vous rencontrez un problème avec votre assurance entreprise ou habitation.
              </p>
              <p className="text-lg text-white py-2">
              Dans tous les cas, je suis là pour vous répondre.
              </p>
              <a
                className="block w-full py-3 px-5 text-center bg-white border border-transparent rounded-md shadow-md text-base font-medium text-sky-700 hover:bg-gray-50 sm:inline-block sm:w-auto mt-4"
                href="/contact/"
              >
                Décrivez moi votre besoin
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}




  