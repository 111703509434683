import React from "react"
import { TrendingUpIcon } from '@heroicons/react/solid'

/* This example requires Tailwind CSS v2.0+ */
export default function Section3() {
    return (
      <div className="bg-gray-50 pt-12 sm:pt-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Faîtes appel à Mickaël Sanchez pour la gestion de votre sinistre
            </h2>
            <p className="mt-3 text-xl text-gray-500 sm:mt-4">
            Fort de mon expérience de plus de 10 ans au sein d’un grand Cabinet national, mon rôle est de vous défendre face à la compagnie d’assurance après un sinistre. 
            </p>
            <p className="mt-3 text-xl text-gray-500 sm:mt-4">
            J’interviens au quotidien pour le compte de tous types de sinistrés : des particuliers, propriétaires ou locataires d’un bien résidentiel (maison, appartement), syndics de copropriété, entreprises (commerce, bureaux…) et pour l’administration publique (mairie et collectivité).
            </p>
            <p className="mt-3 text-xl text-gray-500 sm:mt-4">
            Vous pouvez me mandater à tout moment, dans les heures et jours qui suivent votre sinistre et même dans les semaines qui suivent. 
            </p>
          </div>
        </div>
        <div className="mt-10 pb-12 bg-white sm:pb-16">
          <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-gray-50" />
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-4xl mx-auto">
                <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                  <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Indépendant</dt>
                    <dd className="order-1 text-5xl font-bold text-sky-600">100%</dd>
                  </div>
                  <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Disponible</dt>
                    <dd className="order-1 text-5xl font-bold text-sky-600">24/7</dd>
                  </div>
                  <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Efficace / Réactif</dt>
                    <dd className="order-1 text-5xl font-extrabold text-sky-600"><TrendingUpIcon className="h-12 w-12 mx-auto" /></dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  