import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function Section4() {
  return (
    <div
      id="question"
      className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8"
    >
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            L’expertise d’assurés un métier peu connu
          </h2>
          <p className="mt-3 max-w-3xl mx-auto text-xl text-gray-500 sm:mt-4">
            Apprenez à mieux le comprendre au travers des questions fréquentes
            sur ce sujet.
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div className="flex-shrink-0">
              <StaticImage
                className="h-48 w-full object-cover"
                src="../../images/expert-assure.jpg"
                alt="Expert Assure"
              />
            </div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <p className="text-xl font-semibold text-gray-900">
                  Qu’est-ce qu’un Expert d’assuré :
                </p>
                <p className="mt-3 text-base text-gray-500">
                  Un expert d'assuré est un spécialiste chargé d'intervenir dans
                  le règlement des sinistres. Fin connaisseur de son domaine, il
                  est appelé pour constater les dégâts, estimer les causes du
                  sinistre et évaluer le coût des réparations ou de la remise en
                  état.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div className="flex-shrink-0">
              <StaticImage
                className="h-48 w-full object-cover"
                src="../../images/expert-assureur.jpg"
                alt="Expert assureur"
              />
            </div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <p className="text-xl font-semibold text-gray-900">
                  Quelle est la différence entre un expert d'assuré et un expert
                  d'assureur ?
                </p>
                <p className="mt-3 text-base text-gray-500">
                  L’expert d’assureur est mandaté par l’assureur pour évaluer
                  votre sinistre et les indemnisations qui en résultent.
                </p>
                <p className="mt-3 text-base text-gray-500">
                  L’expert d’assuré est mandaté par vous, l’assuré, et son
                  intérêt est exclusivement de vous défendre et de faire valoir
                  vos droits afin d’obtenir la “plus juste” indemnité pour le
                  préjudice subi.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div className="flex-shrink-0">
              <StaticImage
                className="h-48 w-full object-cover"
                src="../../images/objectif-expert-assure.jpg"
                alt="Ojectif expert assure"
              />
            </div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <p className="text-xl font-semibold text-gray-900">
                  Quel est l'objectif de l'expert d'assuré ?
                </p>
                <p className="mt-3 text-base text-gray-500">
                  Obtenir une indemnité transactionnelle entre experts
                  (d’assurances et d’assurés) sur une base en général bien
                  supérieure à ce que proposerait l’assureur.
                </p>
                <p className="mt-3 text-base text-gray-500">
                  Un expert d’assuré (contre expert) ne travaille pas pour «
                  contrer » toujours les propositions de l’expert de l’assurance
                  : son rôle est de travailler en harmonie en veillant à
                  appliquer toutes les garanties auxquelles vous avez tout
                  simplement droit dans votre contrat.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div className="flex-shrink-0">
              <StaticImage
                className="h-48 w-full object-cover"
                src="../../images/mandater-expert-assure.jpg"
                alt="Mandat expert assure"
              />
            </div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <p className="text-xl font-semibold text-gray-900">
                  Quand puis-je mandater un expert d'assuré ?
                </p>
                <p className="mt-3 text-base text-gray-500">
                  Vous pouvez mandater un expert tant que vous n’avez pas
                  accepté les propositions d’indemnisation de la part de votre
                  assureur. Plus tôt vous faites appel à l’expert, mieux il
                  pourra s’occuper de votre procédure. Une fois choisi, si vous
                  regrettez votre choix, la loi vous laisse 14 jours pour vous
                  rétracter sans invoquer de motif. (article L.121-16 du 17 mars
                  2014)
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div className="flex-shrink-0">
              <StaticImage
                className="h-48 w-full object-cover"
                src="../../images/honoraire-expert-assure.jpg"
                alt="Honoraire expert assure"
              />
            </div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <p className="text-xl font-semibold text-gray-900">
                  Qui paye les honoraires de l'expert d'assuré ?
                </p>
                <p className="mt-3 text-base text-gray-500">
                  Il existe 2 cas :
                </p>
                <ul className="mt-3 text-base text-gray-500">
                  <li>
                    - soit le contrat d’assurance en cours qui couvre le
                    sinistre ne prévoit pas la couverture de Honoraire d’Expert:
                    auquel cas vous devrez payer la prestation
                  </li>
                  <li>
                    - soit le contrat d’assurance prévoit la prise en charge par
                    l’assureur de l’expert d’assuré (sous la mention HONORAIRES
                    D’EXPERT) dans ce cas ils seront pris en charge par ce
                    dernier dans les limites contractuelles précisées
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div className="flex-shrink-0">
              <StaticImage
                className="h-48 w-full object-cover"
                src="../../images/avantage-expert-assure.jpg"
                alt="Avantage expert assure"
              />
            </div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <p className="text-xl font-semibold text-gray-900">
                  Quel est l’intérêt de faire appel à un expert d’assuré ?
                </p>
                <p className="mt-3 text-base text-gray-500">
                  Avec un expert d’assuré, le sinistré sera accompagné et
                  défendu afin de négocier au mieux l’indemnisation de la
                  totalité du préjudice.
                </p>
                <p className="mt-3 text-base text-gray-500">
                  Tous les dommages seront pris en compte, et toutes les
                  garanties présentes dans le contrat d’assurance multirisque
                  habitation (MRH) pour un particulier ou le contrat
                  multi-risque entreprise (MRE) pour un professionnel seront
                  identifiées en vue d’une indemnisation au plus juste du
                  sinistré.
                </p>
                <p className="mt-3 text-base text-gray-500">
                  En effet, la possibilité d’une contre-expertise amiable
                  rééquilibre cette phase d’estimation et chiffrage des
                  dommages.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
