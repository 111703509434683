import React from 'react'
import { ShieldCheckIcon, ClipboardCheckIcon } from '@heroicons/react/outline'

const transferFeatures = [
  {
    id: 1,
    name: 'Conformité',
    description:
      'Vérifier la conformité du risque (nombre de pièces déclarées, dépendances)',
    icon: ClipboardCheckIcon,
  },
  {
    id: 2,
    name: 'Garantie',
    description:
      'Vérifier si la garantie du contrat s’applique (incendie, dégât des eaux etc.)',
    icon: ShieldCheckIcon,
  },
]

export default function Example() {
  return (
    <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        

        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Les pénalités d’assurance
          </h2>
        </div>

        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h3 className="text-sm font-bold text-gray-900 tracking-tight sm:text-2xl">
            En effet, l’intervention d’un expert mandaté par la compagnie aura également pour but de :
            </h3>

            <dl className="mt-10 space-y-10">
              {transferFeatures.map((item) => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-sky-500 text-white">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <img
              className="relative mx-auto rounded-xl shadow-xl"
              width={490}
              src="contrat-sinistre.jpg"
              alt=""
            />
          </div>
        </div>

        

        <div className="relative mt-12 sm:mt-8 lg:mt-8">
          <div className="lg:grid lg:grid-flow-row-dense lg:gap-8 lg:items-center">
            <p className="mt-3 text-lg text-gray-500">
            Nous vous rappelons l’article du Code des assurances 113-9 concernant la non-conformité du risque 
            </p>
            <p className="text-lg text-gray-500">
            L'omission ou la déclaration inexacte de la part de l'assuré dont la mauvaise foi n'est pas établie n'entraîne pas la nullité de l'assurance. 
            </p>
            <p className="text-lg text-gray-500">
            Si elle est constatée avant tout sinistre, l'assureur a le droit soit de maintenir le contrat, moyennant une augmentation de prime acceptée par l'assuré, soit de résilier le contrat dix jours après notification adressée à l'assuré par lettre recommandée, en restituant la portion de la prime payée pour le temps où l'assurance ne court plus. 
            </p>
            <p className="text-lg text-gray-500">
            Dans le cas où la constatation n'a lieu qu'après un sinistre, l'indemnité est réduite en proportion du taux des primes payées par rapport au taux des primes qui auraient été dues, si les risques avaient été complètement et exactement déclarés. 
            </p>
            <p className="text-lg text-gray-500">
            Notre expérience nous a démontré à ce jour que 5 sinistres sur 10 entraînent des pénalités (10%, 20% voir 50% de pénalités) en cas de mauvaise déclaration du contrat. 
            </p>
            <p className="text-lg text-gray-500">
            N’hésitez pas à nous <a className="text-sky-500" href="/contact/">solliciter</a> si vous avez des questions à ce sujet. 
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
