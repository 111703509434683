import React from "react"

import { CheckIcon } from '@heroicons/react/outline'

const tiers = [
  {
    name: 'Mon rôle en tant qu’expert d’assurés',
    href: '/',
    image: "role-assure.jpg",
    priceMonthly: 49,
    description: "J’agis pour les intérêts des sinistrés face à leur compagnie d’assurance. Vous aurez la faculté de pouvoir vous faire représenter par un expert qui pourra discuter, négocier, établir les réclamations et règlement auprès de la compagnie à votre place.",
    features: [
      "Représenter vos intérêts face à l’expert d’assurance lorsque survient un sinistre",
      "Maximaliser vos indemnités",
      "Chiffrage, négociation des indemnités d’assurance",
      "Avoir une gestion optimale de votre sinistre avec une analyse de votre contrat",
      "Estimation préalable d’assurance, valorisation des capitaux, audit et conseil en assurance",
    ],
  },
  {
    name: 'Le type de sinistres sur lesquels j’interviens',
    href: '/',
    image: "type-sinistre.jpg",
    priceMonthly: 79,
    description: "Je peux intervenir sur toute typologie de sinistres, pour les particuliers comme pour les professionnels.",
    features: [
      "Sinistre Incendie",
      "Sinistre dégâts des eaux",
      "Sinistre perte d’exploitation",
      "Sinistre catastrophes naturelles (grêle, sècheresse, inondation)",
      "Effondrement",
      "Bris de machine",
    ],
  },
]

export default function Section2() {
  return (
    <div id="role" className="bg-gray-200">
      <div className="pt-12 sm:pt-16 lg:pt-20">
        <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
            <h2 className="text-3xl font-bold sm:text-4xl lg:text-4xl">Mon rôle et type de sinistre</h2>
          </div>
        </div>
      </div>
      <div className="mt-8 pb-12 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
        <div className="relative">
          <div className="absolute inset-0 h-3/4 bg-gray-200" />
          <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
              {tiers.map((tier) => (
                <div key={tier.name} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                    <img className="h-48 w-full object-cover mb-8" src={tier.image} alt="" />
                    <div>
                      <h3
                        className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-sky-600"
                        id="tier-standard"
                      >
                        {tier.name}
                      </h3>
                    </div>
                    <p className="mt-5 text-lg text-gray-500">{tier.description}</p>
                  </div>
                  <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                    <ul className="space-y-4">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex items-start">
                          <div className="flex-shrink-0">
                            <CheckIcon className="h-6 w-6 text-sky-500" aria-hidden="true" />
                          </div>
                          <p className="ml-3 text-base text-gray-700">{feature}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


  