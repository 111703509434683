import React from "react"
import { Helmet } from "react-helmet"

import Header from "../components/headers/header"
import Banner from "../components/banner"
import Section1 from "../components/home/section1"
import Section2 from "../components/home/section2"
import Section3 from "../components/home/section3"
import Section4 from "../components/home/section4"
import Section5 from "../components/home/section5"
import Footer from "../components/footers/footer"


export default function index() {

  return (
    <div className="bg-white">
      <Helmet>
        <title>Cabinet INTERV'APS | Mickaël Sanchez Expert d'assurés et après sinistre</title>
        <meta name="description" content="Site du Cabinet INTERV'APS et Mickaël Sanchez | Comprendre le rôle d'un Expert d'Assurés, ses objectifs et les modalités d'accompagnement en cas de sinistre" />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Banner />
        <Header />
      </header>

      <main className="relative">
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  )
}